import { Grid, ResponsiveContext, Text, Box, Heading, Anchor, Spinner } from "grommet";
import { useContext } from "react";
import BarChart from "@/components/BarChart";
import DoughnutChart from "@/components/DoughnutChart";
import { CardLayout } from "@/components/Card";
import { useNavigate } from "react-router-dom";
import { useGetDashboard } from "../api";

const parentGrid: any = {
    columns: {
        xsmall: ['100%'],
        small: ['100%'],
        medium: [
            ['small', 'large'],
            ['small', 'large'],
        ],
        large: [
            ['small', 'medium'],
            ['small', 'medium'],
            ['small', 'medium'],
        ],
        xlarge: [
            "1/4", "1/4", "1/4"
        ],
    },
    gap: {
        xsmall: 'large',
        small: 'large',
        medium: 'medium',
        large: 'large',
        xlarge: 'medium',
    },
};

const Content = () => {
    const size = useContext(ResponsiveContext);
    const navigate = useNavigate();

    const { isLoading, data } = useGetDashboard()

    if (isLoading) return <Box fill flex direction="row" justify="center" margin={{ vertical: 'xlarge' }}><Spinner /></Box>

    return (
        <Box flex direction="column" pad={{ horizontal: 'small' }}>
            <Box>
                <Heading level={2}>Telemetry</Heading>
                <Grid columns={parentGrid.columns[size]} gap={parentGrid.gap[size]}>
                    <Anchor href={process.env.REACT_APP_TD} target="_blank" style={{ textDecoration: 'none' }}>
                        <CardLayout background={'url(./assets/images/login_bg4.jpg)'} title={"Dashboard"} subtitle={"Gain real-time insights and analysis by monitoring data seamlessly"} isLink={true} onClick={() => { }}>
                            <Box margin={{ vertical: 'large' }}>
                                <Box flex justify="center" align="center">
                                    <BarChart values={[23, 43, 12, 54, 32, 11, 50, 10]} />
                                </Box>
                            </Box>
                        </CardLayout>
                    </Anchor>

                    <CardLayout background={'background-front'} title={"Reports"} subtitle={"Unlock instant insights delivering crucial data at your fingertips"} isLink={false}
                        onClick={() => { navigate('/reporting') }}
                    >
                        <img alt="Reports" src={'./assets/images/bg6.png'} />
                    </CardLayout>

                    <Anchor href={process.env.REACT_APP_AIOPS} target="_blank" style={{ textDecoration: 'none' }}>
                        <CardLayout background={'background-front'} title={"AIOps"} subtitle={"Elevate operations with our AIOps solution, featuring predictive forecasting and anomaly detection for proactive management"} isLink={true} onClick={() => { }}>
                            <img alt="AIOps" height={'auto'} width={'90%'} style={{ margin: 'auto' }} src={'./assets/images/bg7.png'} />
                        </CardLayout>
                    </Anchor>

                </Grid>
            </Box>

            <Box margin={{ top: '1rem' }}>
                <Heading level={2}>Operations</Heading>
                <Grid columns={parentGrid.columns[size]} gap={parentGrid.gap[size]}>

                    <CardLayout background={'background'} title={"Autodiscovery"} subtitle={"Experience seamless infrastructure management by effortlessly identifying new devices as they are provisioned in your environment"} isLink={false} onClick={() => navigate("/ci-discovery")}>
                        <Box flex direction="row" wrap gap={'medium'} margin={'auto'}>
                            <Box width={'40%'}>
                                <Text weight={'normal'} color={'dark-3'} size="1rem">Compute</Text>
                                <Text weight={'normal'} size="2rem" color={'dark-1'}>12</Text>
                            </Box>

                            <Box width={'40%'}>
                                <Text weight={'normal'} color={'dark-3'} size="1rem">Network</Text>
                                <Text weight={'normal'} size="2rem" color={'dark-1'}>15</Text>
                            </Box>

                            <Box width={'40%'}>
                                <Text weight={'normal'} color={'dark-3'} size="1rem">Storage</Text>
                                <Text weight={'normal'} size="2rem" color={'dark-1'}>8</Text>
                            </Box>

                            <Box width={'40%'}>
                                <Text weight={'normal'} color={'dark-3'} size="1rem">Virtualization</Text>
                                <Text weight={'normal'} size="2rem" color={'dark-1'}>10</Text>
                            </Box>
                        </Box>
                        <Box margin={{ top: '2rem' }}>
                            <BarChart color={"#FF8300"} values={[23, 12, 16, 54, 32, 11, 30, 20]} />
                        </Box>
                    </CardLayout>

                    <CardLayout background={'background'} title={"Compliance"} subtitle={"Ensure regulatory adherence by simplifying the complexities of meeting standards and regulations"} isLink={false} onClick={() => { navigate('/compliance') }}>
                        <Box height={"280px"} width={'100%'} margin={'auto'}>
                            {data?.data && <DoughnutChart labels={Object.keys(data?.data.compliance)} data={Object.values(data?.data.compliance)} />}
                        </Box>
                    </CardLayout>

                    {/* <CardLayout background={'background'} title={"Patch Management"} subtitle={"Keep the system up to date with the latest patches security, features etc..."} isLink={false}>
                        <></>
                    </CardLayout> */}

                    {/* <CardLayout background="background-front" title={"Infrastructure Availability"} subtitle={"A place where you can view the stats of infra being uptime"} isLink={false}>
                        <img alt="Infrastructure Availability" src={'./assets/images/bg8.png'} />
                    </CardLayout> */}

                </Grid>
            </Box>

            {/* <Box margin={{ top: '1rem' }}>
                <Heading level={2}>Misc</Heading>
                <Grid columns={parentGrid.columns[size]} gap={parentGrid.gap[size]}>
                    <CardLayout background={'background'} title={"Firmware Deviation"} subtitle={"An insight on the versions of firmware installed vs latest released OEM"} isLink={false}>
                        <></>
                    </CardLayout>
                </Grid>
            </Box> */}

        </Box >
    );
};

export default Content