import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// const tokenKey = "auth";

// const isTokenExpired = () => {
//   const time = sessionStorage.getItem("expiry");
//   // console.log(time, Date.now(), time < Date.now());
//   if (time) return Number(time) < Date.now();

//   return true;
// };

// const getRefreshedToken = async () => {
//   return await apiClient.post(`/v1.0/user/auth`, {
//     source_id: "64d83c0ff0e0de69c7d469ce",
//     source_user_id: sessionStorage.getItem("userName"),
//   });
// };

// apiClient.interceptors.request.use(
//   (config) => {
//     if (config.url !== "/v1.0/user/auth") {
//       // Check if the token is expired or about to expire
//       if (isTokenExpired()) {
//         // Refresh token if it's about to expire in less than 1 minute
//         return getRefreshedToken().then((newToken) => {
//           // console.log({ newToken });
//           // Update the authorization header with the new token
//           sessionStorage.setItem(tokenKey, newToken?.data?.data?.accessToken);
//           sessionStorage.setItem("expiry", newToken?.data?.data?.validity);
//           config.headers.Authorization = newToken?.data?.data?.accessToken;
//           return config;
//         });
//       }
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     // If the error status is 401 and there is no originalRequest._retry flag,
//     // it means the token has expired and we need to refresh it
//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         const refreshToken = localStorage.getItem('refreshToken');
//         const response = await axios.post('/api/refresh-token', { refreshToken });
//         const { token } = response.data;

//         localStorage.setItem('token', token);

//         // Retry the original request with the new token
//         originalRequest.headers.Authorization = `Bearer ${token}`;
//         return axios(originalRequest);
//       } catch (error) {
//         // Handle refresh token error or redirect to login
//       }
//     }

//     return Promise.reject(error);
//   }
// );
