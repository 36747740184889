import { useContext, createContext, ReactNode, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IAuthContext, ILoginForm, Tenant } from './types'
import { useLogin, useLogout, useTenantInfo } from "./api";
import Cookies from "js-cookie";
import { useOktaAuth } from '@okta/okta-react';

const AuthContext = createContext<IAuthContext | null>(null);
export const COOKIE_TOKEN_KEY = "TC_AUTH"
export const COOKIE_REMEMBER_KEY = "tc-login"

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [user, setUser] = useState<string>(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")!) : null);
    const [token, setToken] = useState(authState?.isAuthenticated ? oktaAuth.getAccessToken() || "" : Cookies.get(COOKIE_TOKEN_KEY) || "");
    const navigate = useNavigate();
    const [body, setBody] = useState<ILoginForm | null>(null)
    const [errorMsg, setErrorMsg] = useState<string>("")
    const [isLoggingOut, setLoggingOut] = useState(false);
    const [tenant, setTenant] = useState<Tenant>({} as Tenant);

    const { data: tenantData, refetch } = useTenantInfo()

    // useEffect(() => {
    //     // Cleanup function to remove cookies
    //     const cleanupCookies = () => {
    //         Cookies.remove(COOKIE_TOKEN_KEY); // Replace with your actual cookie name
    //         Cookies.remove(COOKIE_REMEMBER_KEY);
    //     };

    //     // Run cleanup on component unmount or page refresh
    //     window.addEventListener('beforeunload', cleanupCookies);

    //     return () => {
    //         window.removeEventListener('beforeunload', cleanupCookies);
    //     };
    // }, []);

    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data,
        mutate,
    } = useLogin(body!);

    const {
        isError: logoutIsError,
        isSuccess: logoutIsSuccess,
        mutate: mutateLogout,
    } = useLogout();

    const ssoLogin = async () => {
        localStorage.setItem("ssoLogin", "true");
        oktaAuth.signInWithRedirect({ originalUri: '/' });
    };

    const loginAction = async (body: ILoginForm) => {
        if (body?.rememberMe && body.user_name && body.password) {
            Cookies.set(
                COOKIE_REMEMBER_KEY,
                JSON.stringify({
                    rememberMe: body.rememberMe,
                    user_name: body.user_name,
                    password: body.password,
                }),
                { expires: 30 }, // expires in 30 days
            );
            delete body.rememberMe
        }
        setBody(body)
        setErrorMsg("")
    };

    useEffect(() => {
        if (authState && authState.isAuthenticated && !isLoggingOut) {
            setToken(oktaAuth.getAccessToken()!)
            oktaAuth.getUser().then((user) => { setUser(user?.email as string) })

            const expireTime = new Date().getTime() + 3600 * 1000
            localStorage.removeItem("expires_in")
            localStorage.setItem("expires_in", expireTime + "");
            Cookies.set(COOKIE_TOKEN_KEY, oktaAuth.getAccessToken()!, {
                expires: new Date(expireTime!),
            });
            refetch()
        }
    }, [authState, oktaAuth, isLoggingOut, refetch]);

    useEffect(() => {
        if (tenantData) {
            setTenant(tenantData.data!)
        }
    }, [tenantData])

    useEffect(() => {
        if (body)
            mutate()
    }, [body, mutate])

    useEffect(() => {
        if (isSuccess) {
            const expireTime: number = +data?.data?.expiresAt!;
            const token: string = data?.data?.token!;

            localStorage.setItem("expires_in", expireTime + "");
            localStorage.setItem("user", JSON.stringify(data?.data?.user))
            console.log({ expireTime })
            setToken(token!)
            Cookies.set(COOKIE_TOKEN_KEY, token!, {
                expires: new Date(expireTime!),
            });
            setUser(data?.data?.user!);
            setBody(null)
            refetch()
            // eslint-disable-next-line react-hooks/exhaustive-deps
            navigate("/");
        }
        if (isError) {
            setErrorMsg(error?.response?.data?.message || "Login Failed")
        }
    }, [isLoading, isSuccess, isError, error, data, refetch])


    const logOut = () => {

        setTimeout(() => {
            if (authState?.isAuthenticated) {
                localStorage.removeItem("expires_in");
                localStorage.removeItem("user");
                Cookies.remove(COOKIE_TOKEN_KEY)

                setLoggingOut(true)
                localStorage.removeItem("ssoLogin");
                oktaAuth.signOut()
            }
            else {
                mutateLogout()
            }
        }, 1000);

    };

    useEffect(() => {
        if (logoutIsSuccess || logoutIsError) {
            setToken("")
            localStorage.removeItem("expires_in");
            localStorage.removeItem("user");
            Cookies.remove(COOKIE_TOKEN_KEY)

            // eslint-disable-next-line react-hooks/exhaustive-deps
            navigate("/login");
        }
    }, [logoutIsSuccess, logoutIsError])

    return <AuthContext.Provider value={{
        token, user, loginAction, logOut, isLoading, errorMsg, ssoLogin, tenant
    }}> {children}</AuthContext.Provider >;
};

export default AuthProvider;

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context)
        throw new Error("Auth provider is not available")
    return context;
};