import { COOKIE_TOKEN_KEY } from "@/auth/AuthProvider";
import { apiClient } from "@/helpers/axiosClient";
import Cookies from "js-cookie";

export async function getDataCenter() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/datacenters`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}

export async function getDataStores() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/datastores`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}

export async function getClusters() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/clusters`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}

export async function getVMs() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/vms`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}

export async function getEsxiServers() {
    const response = await apiClient.get(`/v1/ci-discovery/vcenter/esxservers`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}

export async function getCmdbStorageData(product: string) {
    const response = await apiClient.get(`/v1/ci-discovery/storage?product=${product}`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}

export async function getCmdbProduct(product_type: string) {
    const response = await apiClient.get(`/v1/ci-discovery/products?product_type=${product_type}`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}