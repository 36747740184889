import { COOKIE_TOKEN_KEY } from "@/auth/AuthProvider";
import { apiClient } from "@/helpers/axiosClient";
import Cookies from "js-cookie";

export async function getComputeCompliance() {
    const response = await apiClient.get(`/v1/compliance/compute`, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    });
    return response && response.data;
}
