import { useAuth } from "@/auth/AuthProvider";
import Layout from "@/components/Layout";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
    const user = useAuth();
    // console.log(user)
    if (!user.token) return <Navigate to="/login" />;

    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};

export default PrivateRoute;