import { apiClient } from "@/helpers/axiosClient";
import { ILoginForm, Tenant, User } from "../types";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import { ApiResponse } from "@/types";
import Cookies from "js-cookie";
import { COOKIE_TOKEN_KEY } from "../AuthProvider";

export async function login(body: ILoginForm) {
    const response = await apiClient.post('v1/auth/login', body, {
        headers: {
            'content-type': 'application/json'
        }
    })

    return response && response.data
}

export async function logout() {
    const response = await apiClient.post('v1/auth/logout', null, {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    })

    return response && response.data
}

export async function getTenantInfo() {
    const response = await apiClient.get('v1/tenant', {
        headers: {
            Authorization: "Bearer " + Cookies.get(COOKIE_TOKEN_KEY),
        },
    })

    return response && response.data
}

export const useLogin = (body: ILoginForm) =>
    useMutation<ApiResponse<User>, AxiosError<any>>(() => login(body));

export const useLogout = () =>
    useMutation<ApiResponse<null>, AxiosError<any>>(() => logout());

export const useTenantInfo = () =>
    useQuery<ApiResponse<Tenant>, AxiosError>(["tenant"], () => getTenantInfo());