import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import React from 'react';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const options = {
    responsive: true,
    plugins: {
        legend: {
            display: true
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
        datalabels: {
            display: true,
            color: "white",
        }
    },
};

type DoughnutChartProps = {
    data?: number[],
    labels?: string[]
};


const DoughnutChart: React.FC<DoughnutChartProps> = ({ data = [12, 19, 3, 5], labels = ['Failed Nodes', 'Skipped Nodes', 'Passed Nodes', 'Waived Nodes'] }) => {
    const _data = {
        labels: labels.map(x => x.charAt(0).toUpperCase()
            + x.slice(1)),
        datasets: [
            {
                data: data,
                backgroundColor: [
                    '#FC6161',
                    '#FFBC44',
                    '#00E8CF',
                    '#00739D',
                ],
                borderColor: [
                    '#FC6161',
                    '#FFBC44',
                    '#00E8CF',
                    '#00739D',
                ],
                borderWidth: 1,
            },
        ],
    };
    return <Doughnut data={_data} options={options} style={{ margin: 'auto ' }} />;
}

export default DoughnutChart
