import { Box, DataTable, Form, FormField, ResponsiveContext, Select, Spinner, Text } from "grommet"
import { useContext, useEffect, useState } from "react";
import { useCmdbStorage, useCmdbProduct } from "../api";
import moment from "moment";
import { IDisk, IPool, IPort, IServer, IVolume } from "../types";
import { Connectivity, Disc, DriveCage, NetworkDrive, Servers, VirtualStorage } from "grommet-icons";
import { CardWithNumber } from "@/components/Card";

const Storage = () => {
    const size = useContext(ResponsiveContext);
    const [selected, setSelected] = useState<string>("")
    const [selectedItem, selectItem] = useState<string>("" as string)

    const { isLoading, data, refetch, isFetching } = useCmdbStorage(selected!)
    const { isLoading: productsLoading, data: productsData } = useCmdbProduct('storage')

    useEffect(() => {
        if (data?.data?.dataTypes?.length)
            selectItem(data?.data?.dataTypes[0])
    }, [data?.data])

    useEffect(() => {
        if (productsData?.data?.length) {
            const ifNimble = productsData?.data.find(x => x.product_name === "Nimble")
            if (ifNimble)
                setSelected(ifNimble.product_name)
            else
                setSelected(productsData?.data[0]?.product_name)
        }
    }, [productsData?.data])

    useEffect(() => {
        if (selected)
            refetch()
    }, [selected, refetch])

    function handleSelect(item: string): void {
        selectItem(item)
    }

    function capitalizeString(x: string): any {
        return x.split("_").join(" ").split(" ").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")
    }

    const getColor = (item: string) => {
        switch (item.toLowerCase()) {
            case 'disk': return '#7630EA'
            case 'volume': return '#FF8300'
            case 'server': return '#00739D'
            case 'port': return '#F740FF'
            case 'hba': return '#00E8CF'
            default: return '#FEC901'
        }
    }

    const getIcon = (item: string) => {
        switch (item.toLowerCase()) {
            case 'disk': return <Disc size="1.4rem" color="light-1" />
            case 'volume': return <VirtualStorage size="1.3rem" color="light-1" />
            case 'server': return <Servers size="1.3rem" color="light-1" />
            case 'port': return <Connectivity size="1.3rem" color="light-1" />
            case 'hba': return <NetworkDrive size="1.3rem" color="light-1" />
            case 'pool': return <DriveCage size="1.3rem" color="light-1" />
            default: return null
        }
    }

    let _data: any = [];
    if (selectedItem)
        _data = data?.data?.[selectedItem?.toLowerCase()]

    let columns: any[] = []
    if (_data?.length)
        columns = Object.keys(_data[0]).map((x: string) => {
            return {
                property: x,
                header: capitalizeString(x),
                render: (datum: IDisk | IPool | IPort | IVolume | IServer) => <Box><Text>{datum[x] ? x === "last_scanned" ? moment(datum[x]).format("DD MMM YYYY h:mm a") : datum[x] : "--"}</Text></Box>,
            }
        })


    if (isLoading || productsLoading || isFetching)
        return <Box fill flex direction="row" justify="center" margin={{ top: 'xlarge' }}><Spinner /></Box>

    return (
        <Box margin={{ top: '1rem' }} gap={"medium"}>
            <Box flex direction="row" justify="between" align="center">
                <Form>
                    <FormField
                        htmlFor="select-product__input"
                        name="select-product"
                        label="Select Product"
                    >
                        <Select
                            id="select-product"
                            name="select-product"
                            options={productsData?.data?.map(product => product.product_name) || []}
                            value={selected}
                            onChange={({ option }) => setSelected(option)}
                        />
                    </FormField>
                </Form>
                <Box flex wrap direction="row" justify="end" gap={"1.5rem"} margin={{ left: 'auto', bottom: '4px' }}>
                    {/* {data?.data?.dataTypes.map((item: string) =>
                        <Box data-testid={item} key={item} round="small" elevation="small" pad={"small"} background={selectedItem === item ? 'green' : 'white'} onClick={() => { handleSelect(item) }} width={{ min: 'xsmall' }}>
                            <Text textAlign="center" color={'dark-1'} weight={600} size="small">{item}</Text>
                        </Box>)} */}
                    {data?.data?.dataTypes.map((item: string) => <CardWithNumber key={item} selectedItem={selectedItem} title={item} value={data?.data?.[item?.toLowerCase()].length} icon={getIcon(item)} color={getColor(item)} onClick={handleSelect} />)}
                </Box>
            </Box>

            {data?.data?.dataTypes?.length ? <Box flex justify="center" align="center" fill>
                <DataTable
                    key={selectedItem}
                    aria-describedby="storage"
                    data={_data}
                    columns={columns}
                    fill
                    pad="xsmall"
                    paginate={{
                        border: 'top',
                        direction: 'row',
                        fill: 'horizontal',
                        flex: false,
                        justify: !['xsmall', 'small'].includes(size) ? 'end' : 'center',
                        pad: { top: 'xsmall' },
                    }}
                    step={10}
                    sortable
                />
            </Box> : <Box fill flex direction="row" justify="center" margin={{ top: 'xlarge' }}><Text size="large">No Record Found</Text></Box>}
        </Box>
    )
}

export default Storage;

