import moment from "moment";

export const emailValidation: any = [
    {
        regexp: new RegExp('[^@ \\t\\r\\n]+@'),
        message: 'Enter a valid email address.',
        status: 'error',
    },
    {
        regexp: new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+'),
        message: 'Enter a valid email address.',
        status: 'error',
    },
    {
        regexp: new RegExp('[^@ \\t\\r\\n]+@[^@ \\t\\r\\n]+\\.[^@ \\t\\r\\n]+'),
        message: 'Enter a valid email address.',
        status: 'error',
    },
];

export const convertSecondstoMinHr = (sec: number) => {
    const duration = moment.duration(sec, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${hours > 0 ? hours + " hr " : ""}${minutes > 0 ? minutes + " min " : ""}${seconds > 0 ? seconds + " sec" : ""}`;
}