import { CardWithNumber } from "@/components/Card";
import { Box, DataTable, Grid, ResponsiveContext, Spinner, Text } from "grommet"
import { Archive, Cluster, DocumentStore, Host, VirtualMachine } from "grommet-icons";
import { useContext, useState } from "react";
import { useEsxiServers, useGetClusters, useGetDataCenters, useGetDataStores, useGetVMs } from "../api";
import moment from "moment";
import {
    StatusGoodSmall,
    StatusWarningSmall,
    StatusCriticalSmall,
    StatusDisabledSmall
} from 'grommet-icons';
import { IClusters, IDataCenters, IDataStores, IEsxiServers, IVms } from "../types";

const parentGrid: any = {
    columns: {
        xsmall: ['100%'],
        small: ["auto", "auto"],
        medium: [
            "auto", "auto", "auto"
        ],
        large: [
            "auto", "auto", "auto", "auto"
        ],
        xlarge: [
            "small", "small", "small", "small", "small"
        ],
    },
    gap: {
        xsmall: 'large',
        small: 'medium',
        medium: 'medium',
        large: 'medium',
        xlarge: 'large',
    },
};

const status: {
    green: string;
    yellow: string;
    gray: string;
    red: string;
} = {
    green: "Normal",
    yellow: "Warning.",
    gray: "Unknown.",
    red: "Critical."

    // green: "Guest operating system is responding normally",
    // yellow: "Intermittent heartbeat. A Yellow status may be caused by heavy guest OS usage.",
    // gray: "VMware Tools are not installed or not running.",
    // red: "No heartbeat. Guest operating system may have stopped responding."
}

function getIcon(status: string | undefined): import("react").ReactNode {
    switch (status) {
        case "green": return <StatusGoodSmall color={status} />
        case "red": return <StatusCriticalSmall color={status} />
        case "yellow": return <StatusWarningSmall color={status} />
        case "gray": return <StatusDisabledSmall color={"border-weak"} />
        default:
            break;
    }
}

const VMColumns = [
    {
        property: 'vm_name',
        header: 'VM Name',
        render: (datum: IVms) => <Box width={'210px'}><Text >{datum.vm_name || "--"}</Text></Box>,
        primary: true,
    },
    {
        property: 'vm_overall_status',
        header: 'VM Overall Status',
        render: (datum: IVms) =>
            <Box width={'160px'} direction="row" align="center" gap="small">
                {getIcon(datum?.vm_overall_status)}
                <Text>{datum.vm_overall_status && status[datum?.vm_overall_status]}</Text>
            </Box>,
        sortable: false,
    },
    {
        property: 'guest_heartbeat_status',
        header: 'Guest Heartbeat Status',
        render: (datum: IVms) => <Box width={'160px'} direction="row" align="center" gap="small">
            {getIcon(datum?.guest_heartbeat_status)}
            <Text>{datum.guest_heartbeat_status && status[datum?.guest_heartbeat_status]}</Text>
        </Box>,
    },
    {
        property: 'guest_host_name',
        header: 'Guest Hostname',
        render: (datum: IVms) => <Box width={'200px'}><Text >{datum.guest_host_name || "--"}</Text></Box>,
    },
    {
        property: 'guest_state',
        header: 'Guest State',
        render: (datum: IVms) => <Box width={'120px'}><Text >{datum.guest_state || "--"}</Text></Box>,
    },
    {
        property: 'guest_ip_address',
        header: 'Guest IP Address',
        render: (datum: IVms) => <Box width={'160px'}><Text >{datum.guest_ip_address || "--"}</Text></Box>,
    },
    {
        property: 'guest_uptime',
        header: 'Guest Up Time',
        render: (datum: IVms) => <Box width={'140px'}><Text >{datum.guest_uptime || "--"}</Text></Box>,
    },
    {
        property: 'guest_memory_usage',
        header: 'Guest Memory Usage',
        render: (datum: IVms) => <Box width={'180px'}><Text >{datum.guest_memory_usage || "--"}</Text></Box>,
        units: 'MB',
    },
    {
        property: 'guest_overall_cpu_usage',
        header: 'Guest Overall CPU Usage',
        render: (datum: IVms) => <Box width={'140px'}><Text >{datum.guest_overall_cpu_usage || "--"}</Text></Box>,
    },
    {
        property: 'uuid',
        header: 'UUID',
        render: (datum: IVms) => <Box ><Text >{datum.uuid || "--"}</Text></Box>,
        sortable: false,
    },

    {
        property: 'memory_size',
        header: 'Memory Size',
        render: (datum: IVms) => <Box width={'120px'}><Text >{datum.memory_size || "--"}</Text></Box>,
    },
    {
        property: 'guest_os',
        header: 'Guest OS',
        render: (datum: IVms) => <Box width={'200px'}><Text >{datum.guest_os || "--"}</Text></Box>,
    },

    {
        property: 'vdisk_count',
        header: 'vDisks Count',
        render: (datum: IVms) => <Box width={'200px'}><Text >{datum.vdisk_count || "--"}</Text></Box>,
    },
    {
        property: 'eth_card_count',
        header: 'Ethernet Card Count',
        render: (datum: IVms) => <Box width={'180px'}><Text >{datum.eth_card_count || "--"}</Text></Box>,
    },
    {
        property: 'vcpu_count',
        header: 'vCPU Count',
        render: (datum: IVms) => <Box width={'120px'}><Text >{datum.vcpu_count || "--"}</Text></Box>,
    },
    {
        property: 'last_scanned',
        header: 'Last Scanned',
        render: (datum: IVms) => <Box width={'170px'}><Text >{datum.last_scanned ? moment(datum?.last_scanned).format("DD MMM YYYY h:mm a") : "--"}</Text></Box>,
    },
];

const dataCenterColumns = [
    {
        property: 'name',
        header: 'Name',
        render: (datum: IDataCenters) => <Box><Text >{datum.name || "--"}</Text></Box>,
        primary: true,
    },

    {
        property: 'hostFolder',
        header: 'Host Folder',
        render: (datum: IDataCenters) => <Box><Text >{datum?.hostfolder || "--"}</Text></Box>,
        sortable: false,
    },

    {
        property: 'vmFolder',
        header: 'VM Folder',
        render: (datum: IDataCenters) => <Box><Text >{datum?.vmfolder || "--"}</Text></Box>,
    },
    {
        property: 'overallstatus',
        header: 'Overall Status',
        render: (datum: IDataCenters) => <Box width={'160px'} direction="row" align="center" gap="small">
            {getIcon(datum?.overallstatus)}
            <Text>{datum.overallstatus && status[datum?.overallstatus]}</Text>
        </Box>,
    },
    {
        property: 'last_scanned',
        header: 'Last Scanned',
        render: (datum: IDataCenters) => <Box><Text >{datum.last_scanned ? moment(datum?.last_scanned).format("DD MMM YYYY h:mm a") : "--"}</Text></Box>,
    }
];

const clusterColumns = [
    {
        property: 'name',
        header: 'Name',
        render: (datum: IClusters) => <Box width={'160px'}><Text >{datum.name || "--"}</Text></Box>,
        primary: true,
    },
    {
        property: 'effectivecpu',
        header: 'Effective CPU',
        render: (datum: IClusters) => <Box width={'110px'}><Text >{datum.effectivecpu || "--"}</Text></Box>,
        sortable: false,
    },
    {
        property: 'effective_memory',
        header: 'Effective Memory',
        render: (datum: IClusters) => <Box ><Text >{datum.effective_memory || "--"}</Text></Box>,
    },
    {
        property: 'effective_hosts',
        header: 'Effective Hosts',
        render: (datum: IClusters) => <Box ><Text >{datum.effective_hosts || "--"}</Text></Box>,
    },
    {
        property: 'num_hosts',
        header: 'Hosts',
        render: (datum: IClusters) => <Box ><Text >{datum.num_hosts || "--"}</Text></Box>,
    },
    {
        property: 'total_cpu',
        header: 'Total CPU',
        render: (datum: IClusters) => <Box width={'100px'}><Text >{datum.total_cpu || "--"}</Text></Box>,
    },
    {
        property: 'total_memory',
        header: 'Total Memory',
        render: (datum: IClusters) => <Box ><Text >{datum.total_memory || "--"}</Text></Box>,
    },
    {
        property: 'num_cpucores',
        header: 'CPU Cores',
        render: (datum: IClusters) => <Box ><Text >{datum.num_cpucores || "--"}</Text></Box>,
    },
    {
        property: 'num_cputhreads',
        header: 'CPU Threads',
        render: (datum: IClusters) => <Box ><Text >{datum.num_cputhreads || "--"}</Text></Box>,
    },
    {
        property: 'cluster_maintenance',
        header: 'Cluster Maintenance',
        render: (datum: IClusters) => <Box><Text >{datum.cluster_maintenance || "--"}</Text></Box>,
    },
    {
        property: 'drsscore',
        header: 'DRS Score',
        render: (datum: IClusters) => <Box><Text >{datum.drsscore || "--"}</Text></Box>,
    },
    {
        property: 'last_scanned',
        header: 'Last Scanned',
        render: (datum: IClusters) => <Box><Text >{datum.last_scanned ? moment(datum?.last_scanned).format("DD MMM YYYY h:mm a") : "--"}</Text></Box>,
    }
];

const exsiColumns = [
    {
        property: 'name',
        header: 'Name',
        render: (datum: IEsxiServers) => <Box margin={{ end: 'small' }}><Text >{datum.name || "--"}</Text></Box>,
        primary: true,
    },

    {
        property: 'uuid',
        header: 'UUID',
        render: (datum: IEsxiServers) => <Box width={'100px'}><Text >{datum.uuid || "--"}</Text></Box>,
        sortable: false,
    },

    {
        property: 'cpu_core_count',
        header: 'CPU Core Count',
        render: (datum: IEsxiServers) => <Box width={'100px'}><Text >{datum.cpu_core_count || "--"}</Text></Box>,
    },
    {
        property: 'cpu_count',
        header: 'CPU Count',
        render: (datum: IEsxiServers) => <Box><Text >{datum.cpu_count || "--"}</Text></Box>,
    },
    {
        property: 'cpu_model',
        header: 'CPU Model',
        render: (datum: IEsxiServers) => <Box width={'200px'}><Text >{datum.cpu_model || "--"}</Text></Box>,
    },
    {
        property: 'cpu_speed',
        header: 'CPU Speed',
        render: (datum: IEsxiServers) => <Box><Text >{datum.cpu_speed || "--"}</Text></Box>,
    },
    {
        property: 'cpu_type',
        header: 'CPU Type',
        render: (datum: IEsxiServers) => <Box width={'200px'}><Text >{datum.cpu_type || "--"}</Text></Box>,
    },
    {
        property: 'manufacturer',
        header: 'Manufacturer',
        render: (datum: IEsxiServers) => <Box><Text >{datum.manufacturer || "--"}</Text></Box>,
    },
    {
        property: 'model',
        header: 'Model',
        render: (datum: IEsxiServers) => <Box width={'110px'}><Text >{datum.model || "--"}</Text></Box>,
    },
    {
        property: 'ram',
        header: 'RAM',
        render: (datum: IEsxiServers) => <Box width={'100px'}><Text >{datum.ram || "--"}</Text></Box>,
    },
    {
        property: 'last_scanned',
        header: 'Last Scanned',
        render: (datum: IEsxiServers) => <Box><Text >{datum.last_scanned ? moment(datum?.last_scanned).format("DD MMM YYYY h:mm a") : "--"}</Text></Box>,
    }
];

const dataStoreColumns = [
    {
        property: 'url',
        header: 'URL',
        render: (datum: IDataStores) => <Box ><Text >{datum.url || "--"}</Text></Box>,
        primary: true,
    },
    {
        property: 'freespace',
        header: 'Free Space',
        render: (datum: IDataStores) => <Box ><Text >{datum.freespace || "--"}</Text></Box>,
    },
    {
        property: 'capacity',
        header: 'Capacity',
        render: (datum: IDataStores) => <Box ><Text >{datum.capacity || "--"}</Text></Box>,
    },
    {
        property: 'uuid',
        header: 'UUID',
        render: (datum: IDataStores) => <Box ><Text >{datum.uuid || "--"}</Text></Box>,
    }, {
        property: 'type',
        header: 'Type',
        render: (datum: IDataStores) => <Box ><Text >{datum.type || "--"}</Text></Box>,
    },
    {
        property: 'last_scanned',
        header: 'Last Scanned',
        render: (datum: IDataStores) => <Box ><Text >{datum.last_scanned ? moment(datum?.last_scanned).format("DD MMM YYYY h:mm a") : "--"}</Text></Box>,
    }
];

const Virtualization = () => {
    const size = useContext(ResponsiveContext);
    const { isLoading, data } = useGetDataCenters()
    const { isLoading: clusterLoading, data: clusters } = useGetClusters()
    const { isLoading: vmLoading, data: vms } = useGetVMs()
    const { isLoading: dataStoreLoading, data: dataStores } = useGetDataStores()
    const { isLoading: esxiLoading, data: esxi } = useEsxiServers()

    const [selectedItem, selectItem] = useState<string>("Data Center")

    function handleSelect(item: string): void {
        selectItem(item)
    }

    const tiles: any = [{
        title: 'Data Center',
        value: data?.data?.length || 0,
        icon: <Archive size="1.3rem" color="light-1" />,
        color: "#7630EA"
    }, {
        title: 'Clusters',
        value: clusters?.data?.length || 0,
        icon: <Cluster size="1.3rem" color="light-1" />,
        color: "#FF8300"
    }, {
        title: 'ESXi Hosts',
        value: esxi?.data?.length || 0,
        icon: <Host size="1.3rem" color="light-1" />,
        color: "#00739D"
    }, {
        title: 'Total VMs',
        value: vms?.data?.length || 0,
        icon: <VirtualMachine size="1.3rem" color="light-1" />,
        color: "#FEC901"
    }, {
        title: 'Data Store',
        value: dataStores?.data?.length || 0,
        icon: <DocumentStore size="1.3rem" color="light-1" />,
        color: "#00E8CF"
    },]

    let _data: any = [];
    switch (selectedItem) {
        case "Clusters":
            _data = clusters?.data || []
            break;
        case "Data Center":
            _data = data?.data || []
            break;
        case "Total VMs":
            _data = vms?.data || []
            break;
        case "ESXi Hosts":
            _data = esxi?.data || []
            break;
        case "Data Store":
            _data = dataStores?.data || []
            break;
    }

    function getColumns(): any[] | undefined {
        switch (selectedItem) {
            case "Clusters":
                return clusterColumns
            case "Data Center":
                return dataCenterColumns
            case "Total VMs":
                return VMColumns
            case "ESXi Hosts":
                return exsiColumns
            case "Data Store":
                return dataStoreColumns || []
            default:
                return [];
        }
    }
    const loading = isLoading || clusterLoading || vmLoading || dataStoreLoading || esxiLoading

    return (
        <Box margin={{ top: '2.2rem' }} gap={"medium"}>
            <Grid columns={parentGrid.columns[size]} gap={parentGrid.gap[size]} margin={{ horizontal: 'auto' }}>
                {tiles.map((item: any) => <CardWithNumber key={item.title} selectedItem={selectedItem} title={item.title} value={item.value} icon={item.icon} color={item.color} onClick={handleSelect} />)}
            </Grid>

            <Box flex justify="center" align="center" fill>
                {loading ?
                    <Spinner />
                    : _data.length > 0 ?
                        <DataTable
                            key={selectedItem}
                            aria-describedby="virtualization"
                            data={_data}
                            columns={getColumns()}
                            fill
                            pad="xsmall"
                            paginate={{
                                border: 'top',
                                direction: 'row',
                                fill: 'horizontal',
                                flex: false,
                                justify: !['xsmall', 'small'].includes(size) ? 'end' : 'center',
                                pad: { top: 'xsmall' },
                            }}
                            step={10}
                            sortable
                        /> :
                        <Text margin={{ vertical: 'xlarge' }} size="large">No Record Found</Text>}
            </Box>
        </Box>
    )
}

export default Virtualization
