import { Box, Card, CardBody, Text } from 'grommet'
import { Share } from 'grommet-icons'
import React from 'react'

export const CardLayout = ({ children, isLink, title, subtitle, background, onClick, isDarkBg = false }: {
    children: React.ReactNode, isLink: boolean, subtitle: string, title: string, background: string, onClick
    ?: () => void, isDarkBg?: boolean
}) => (
    <Card background={background} fill onClick={onClick} style={{ position: 'relative' }}>
        {isLink && <Share style={{ position: 'absolute', right: '10px', top: '10px' }} size="small" color="dark-3" />}
        <CardBody gap="small" align="start" flex="grow">
            <Box margin={{ bottom: 'xsmall' }}>
                <Text weight={'bold'} color={isDarkBg ? 'light-1' : 'dark-1'} size="1.4rem">{title}</Text>
                <Text size="small" weight={"normal"} color={isDarkBg ? 'light-1' : 'dark-1'}>{subtitle}</Text>
            </Box>
            <Box width={'100%'}>
                {children}
            </Box>
        </CardBody>
    </Card>
)

export const CardWithNumber = ({ title, value, icon, color, onClick, selectedItem }: {
    title: string, value: number, icon?: React.ReactNode, color: string, onClick
    : (item: string) => void, selectedItem: string
}) => (
    <Card width={{ min: '8rem' }} data-testid={title} border={selectedItem === title ? { size: 'small', color: 'green' } : { size: '0' }} background={`${color}1a`} onClick={() => onClick(title)} >
        <CardBody align="start" flex="grow" pad={'1.1rem'}>
            <Box gap="small" fill flex direction="row" align="center" justify="around" >
                {icon && <Box round="100%" background={color} pad={'small'} style={{ opacity: 0.6 }}>
                    {icon}
                </Box>}
                <Box flex align='center'>
                    <Text weight={600} size="2rem" color={'dark-1'}>{value}</Text>
                    <Text color={'dark-2'} size="small">{title}</Text>
                </Box>
            </Box>
        </CardBody>
    </Card>
)

